export const ADD_FAVORITE_SYMBOL = "ADD_FAVORITE_SYMBOL";
export const REMOVE_FAVORITE_SYMBOL = "REMOVE_FAVORITE_SYMBOL";

export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE";

export const CHANGE_SIDEBAR_SORT_TYPE = "CHANGE_SIDEBAR_SORT_TYPE";

export const ADD_WALLET_ADDRESS = "ADD_WALLET_ADDRESS";
export const REMOVE_WALLET_ADDRESS = "REMOVE_WALLET_ADDRESS";
export const CHANGE_ACTIVE_WALLET_ADDRESS = "CHANGE_ACTIVE_WALLET_ADDRESS";
