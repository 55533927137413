export const MODAL_OPEN_CREATE_TOKEN = "MODAL_OPEN_CREATE_TOKEN";
export const MODAL_CLOSE_CREATE_TOKEN = "MODAL_CLOSE_CREATE_TOKEN";
export const MODAL_CHECK_CREATE_TOKEN = "MODAL_CHECK_CREATE_TOKEN";
export const MODAL_CLEAR_CREATE_TOKEN = "MODAL_CLEAR_CREATE_TOKEN";

export const MODAL_OPEN_ADD_SUPPORT = "MODAL_OPEN_ADD_SUPPORT";
export const MODAL_CLOSE_ADD_SUPPORT = "MODAL_CLOSE_ADD_SUPPORT";
export const MODAL_CLEAR_ADD_SUPPORT = "MODAL_CLEAR_ADD_SUPPORT";

export const MODAL_OPEN_SHOW_DRAWERS = "MODAL_OPEN_SHOW_DRAWERS";
export const MODAL_CLOSE_SHOW_DRAWERS = "MODAL_CLOSE_SHOW_DRAWERS";

export const MODAL_OPEN_EDIT_INFO = "MODAL_OPEN_EDIT_INFO";
export const MODAL_CLOSE_EDIT_INFO = "MODAL_CLOSE_EDIT_INFO";

export const MODAL_OPEN_ADD_WALLET = "MODAL_OPEN_ADD_WALLET";
export const MODAL_CLOSE_ADD_WALLET = "MODAL_CLOSE_ADD_WALLET";

export const MODAL_OPEN_WITHDRAW = "MODAL_OPEN_WITHDRAW";
export const MODAL_CLOSE_WITHDRAW = "MODAL_CLOSE_WITHDRAW";
